.m-0 {
  margin: 0;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.m-30 {
  margin: 30px;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mx-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mt-0 {
  margin-top: 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mr-0 {
  margin-right: 0;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.ml-0 {
  margin-left: 0;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
