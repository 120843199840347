.p-0 {
  padding: 0;
}
.p-10 {
  padding: 10px;
}
.p-20 {
  padding: 20px;
}
.p-30 {
  padding: 30px;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.pt-0 {
  padding-top: 0;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pr-0 {
  padding-right: 0;
}
.pr-10 {
  padding-right: 10px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pl-0 {
  padding-left: 0;
}
.pl-10 {
  padding-left: 10px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-30 {
  padding-left: 30px;
}

#card-expiry-month-container,
#card-expiry-year-container {
  .nz-select-top-control {
    border: 1px solid #c7c7c7;
  }
}

.payment-details-container {
  .skeleton-element {
    display: flex;
    gap: 2em;
    nz-skeleton-element {
      width: 50%;
      height: 120px;
      margin-bottom: 2em;
      .ant-skeleton-button {
        width: 100%;
        height: 100%;

      }
    }
  }
}
