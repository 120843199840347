.shadow-sm {
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px 0, rgba(0, 0, 0, 0.06) 0 1px 2px 0;
}
.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0 2px 4px -1px;
}
.shadow-lg {
  box-shadow: rgba(0, 0, 0, 0.1) 0 10 15px -3px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}
.shadow-xl {
  box-shadow: rgba(0, 0, 0, 0.1) 0 20 25px -5px, rgba(0, 0, 0, 0.04) 0 10 10 -5px;
}
.shadow-2xl {
  box-shadow: rgba(0, 0, 0, 0.25) 0 25px 50 -12px;
}
