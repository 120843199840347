.h1-responsive {
  font-size: 150%;
}

.h2-responsive {
  font-size: 145%;
}

.h3-responsive {
  font-size: 135%;
}

.h4-responsive {
  font-size: 135%;
}

.h5-responsive {
  font-size: 135%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@media (min-width: 576px) {
  .h1-responsive {
    font-size: 170%;
  }

  .h2-responsive {
    font-size: 140%;
  }

  .h3-responsive {
    font-size: 125%;
  }

  .h4-responsive {
    font-size: 125%;
  }

  .h5-responsive {
    font-size: 125%;
  }
}

@media (min-width: 768px) {
  .h1-responsive {
    font-size: 200%;
  }

  .h2-responsive {
    font-size: 170%;
  }

  .h3-responsive {
    font-size: 140%;
  }

  .h4-responsive {
    font-size: 125%;
  }

  .h5-responsive {
    font-size: 125%;
  }
}

@media (min-width: 992px) {
  .h1-responsive {
    font-size: 200%;
  }

  .h2-responsive {
    font-size: 170%;
  }

  .h3-responsive {
    font-size: 140%;
  }

  .h4-responsive {
    font-size: 125%;
  }

  .h5-responsive {
    font-size: 125%;
  }
}

@media (min-width: 1200px) {
  .h1-responsive {
    font-size: 250%;
  }

  .h2-responsive {
    font-size: 200%;
  }

  .h3-responsive {
    font-size: 170%;
  }

  .h4-responsive {
    font-size: 140%;
  }

  .h5-responsive {
    font-size: 125%;
  }
}

.ant-typography-edit-content {
  textarea {
    background-color: transparent;
    width: 100px !important;
    appearance: none;
  }
}
