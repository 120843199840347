@import "./_images.less";
@import "./_typography.less";
@import "./_sizing.less";
@import "./_shadows.less";
@import "./_margins.less";
@import "./_padding.less";


body:not(.crypto),
body:not(.gaming),
body:not(.fashion),
body:not(.flowers){
  nz-layout{
    background-image: linear-gradient(43deg, #a9e7ff 0%, #e4dbfa 100%);
  }
}

body.crypto{
  nz-layout{
    background-image: linear-gradient(65deg, #dbdffd 0%, #baf6ff 100%);
  }
}

body.gaming{
  nz-layout{
    background-image: linear-gradient(315deg, #ffebe4 0%, #fff8e0 100%);
  }
}

body.fashion{
  nz-layout{
    background-image: linear-gradient(45deg, #d4efff 0%, #daffd7 100%);
  }
}

body.flowers{
  nz-layout{
    background-image: linear-gradient(45deg, #fbe1e5 0%, #fbf5f5 100%);
  }
}

body.success{
  nz-layout{
    background-image: linear-gradient(315deg, #a7fbd0 0%, #bbffb6 74%);
  }
}

body.fail{
  nz-layout{
    background-image:linear-gradient(315deg, #ffc8b6 0%, #ffa395e3 74%);
  }
}

.grad-button::before,
.final-pay-btn::before {
  transition: all 0.2s ease-in-out !important;
  display: block !important;
  // inset:0;
  opacity: .1 !important;
  width: 0 !important;
}

.grad-button:hover::before,
.final-pay-btn:hover::before {
  width: 102% !important;
}
